import React          from 'react';
import AntdInput      from 'antd/lib/input';
import { InputProps } from 'antd/lib/input/Input';

class Input extends React.Component<InputProps> {
	public static defaultProps = {
		size: 'large',
	};

	public render() {
		return (
			<AntdInput
				{...this.props}
			/>
		);
	}
}

export default Input;
