import {observable} from 'mobx';

import {ApiCollection, SplitViewCollection} from 'modelx-jts-common';

import AbstractSplitViewStore         from './AbstractSplitViewStore';
import AccountingDocumentExpenseModel from '../models/AccountingDocumentExpenseModel';
import AccountingDocumentModel        from '../models/AccountingDocumentModel';
import ExpenseModel                   from '../models/ExpenseModel';
import ExpensePaymentVoucherIdModel   from '../models/ExpensePaymentVoucherIdModel';
import ExpensePaymentVoucherModel from '../models/ExpensePaymentVoucherModel';
import UserApiCollection          from '../models/collections/UserApiCollection';
import ExpensePaymentVoucherAccountingDocumentExpenseModel
	from '../models/ExpensePaymentVoucherAccountingDocumentExpenseModel';
import ExpenseSupplierContactModel    from '../models/ExpenseSupplierContactModel';
import SupplierModel                  from '../models/SupplierModel';
import EntityModel                    from '../models/fondationsModels/EntityModel';

class PaymentVoucherSplitViewStore extends AbstractSplitViewStore {
	@observable public splitViewAccountingDocumentCollection = new ApiCollection(AccountingDocumentModel);
	@observable public splitViewAccountingDocumentExpenseCollection = new ApiCollection(AccountingDocumentExpenseModel);
	@observable public splitViewEntityCollection = new ApiCollection(EntityModel);
	@observable public splitViewExpenseCollection = new ApiCollection(ExpenseModel);
	@observable public splitViewExpensePaymentVoucherAccountingDocumentExpenseCollection = new ApiCollection(ExpensePaymentVoucherAccountingDocumentExpenseModel);
	@observable public splitViewExpensePaymentVoucherCollection = new SplitViewCollection(ExpensePaymentVoucherModel, ExpensePaymentVoucherIdModel);
	@observable public splitViewExpenseSupplierCollection = new ApiCollection(SupplierModel);
	@observable public splitViewExpenseSupplierContactCollection = new ApiCollection(ExpenseSupplierContactModel);
	@observable public splitViewUserCollection = new UserApiCollection();

}

export default new PaymentVoucherSplitViewStore();
