import { observer }      from 'mobx-react';
import React             from 'react';
import AntdInputPassword from 'antd/lib/input/Password';
import { PasswordProps } from 'antd/lib/input';

@observer
class InputPassword extends React.Component<PasswordProps> {
	public static defaultProps = {
		size: 'large',
	};

	public render() {
		return (
			<AntdInputPassword
				{...this.props}
			/>
		);
	}
}

export default InputPassword;
