import React                         from 'react';
import Form                          from 'antd/lib/form';
import i18n                          from '../../../components/i18n';
import Trans                         from '../../../components/Trans';
import UserModel                     from '../../../models/fondationsModels/UserModel';
import { passwordStrengthValidator } from '../../utils/passwordStrengthValidator';
import {
	JtsForm,
	ConfirmInput,
	IJtsFormProps,
}                                    from 'react-jts-common';

export interface IModifyAccountPasswordFormProps extends IJtsFormProps {
	model: UserModel;
}

class ModifyAccountPasswordForm extends React.Component<IModifyAccountPasswordFormProps> {

	public render() {
		const {
			form,
			model,
		} = this.props;

		const { getFieldDecorator } = form;

		return <JtsForm
			{...this.props}
			onSubmit={params => model.patch(params)}
			mapParams={values => ({ plainPassword: values.plainPassword })}
			notificationSuccessMessage={<Trans id="Le mot de passe a bien été modifié." />}
			notificationErrorMessage={<Trans id="Le mot de passe n'a pas été modifié." />}
		>
			{getFieldDecorator('plainPassword', {
				rules: [
					{
						required: true,
						message: i18n._('Veuillez renseigner un mot de passe'),
					},
					{ validator: passwordStrengthValidator },
				],
			})(
				<ConfirmInput
					formParent={form}
					labels={[
						<Trans id="Nouveau mot de passe"/>,
						<Trans id="Confirmer mot de passe"/>
					]}
					placeholders={[
						i18n._('Mot de passe'),
						i18n._('Veuillez renseigner un mot de passe')
					]}
					type="password"
					size="large"
				/>
			)}
		</JtsForm>;
	}
}

export default Form.create<IModifyAccountPasswordFormProps>()(ModifyAccountPasswordForm);
