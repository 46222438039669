import '../../styles/ant-theme.less';
import './App.scss';

import React, { Suspense }                 from 'react';
import {
	Route,
	BrowserRouter as Router,
	Switch,
}                                          from 'react-router-dom';
import { unpackCatalog }                   from 'lingui-i18n';
import notification                        from 'antd/lib/notification';
import {
	observer,
	Provider as MobxProvider,
}                                          from 'mobx-react';
import ConfigProvider                      from 'antd/lib/config-provider';
import moment                              from 'moment';
import i18n, { I18nManager, loadCatalogs } from '../i18n';
import mobxStore                           from '../../store';
import Spin                                from 'antd/lib/spin';
import 'moment/locale/fr';

moment.locale('fr');

@observer
class App extends React.Component {

	state = {
		language             : 'fr_FR',
		catalogs             : {},
		antI18n              : {},
		projectCatalogs      : {},
	};

	loadLanguage = (language) => {

		// get locale file name for momentjs
		const momentlocale = language.split('_')[0];

		Promise.all([
			// load project translations
			import(
				/* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
				`../../../locale//${language}/messages.js`),

			// load ant design translations
			import(
				/* webpackMode: "lazy", webpackChunkName: "antI18n-[index]" */
				`antd/lib/locale-provider/${language}.js`),

			// load moment locale
			import(
				/* webpackMode: "lazy", webpackChunkName: "momentLocale-[index]" */
				`moment/locale/${momentlocale}.js`).catch(() => {
			})
		]).then(([
			projectCatalogs,
			customMessages,
			antI18n
		]) => {
			// set moment locale globally
			moment.locale(language);

			// merge project translations with wbc components translations and custom translations
			const catalogs = {
				...catalogs,
				messages: {
					...unpackCatalog(projectCatalogs).messages,
					...customMessages,
				}
			};

			loadCatalogs({
				...this.state.catalogs,
				[language]: catalogs
			});

			i18n.activate(language);

			this.setState(state => ({
				catalogs: {
					...state.catalogs,
					[language]: catalogs
				},
				antI18n: antI18n.default,
				projectCatalogs
			}));
		});
	};

	componentDidMount() {
		this.loadLanguage(this.state.language);
		notification.config({
			duration: 3,
			top     : 80
		});

		// load group collection at start
		if (!mobxStore.appStore.currentCompanyGroupCollection.isLoaded && !mobxStore.appStore.currentCompanyGroupCollection.isLoading) {
			mobxStore.appStore.currentCompanyGroupCollection
				.list()
				.then(() => mobxStore.appStore.orderGroupById());
		}

		mobxStore.appStore.history = this.props.history;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			language,
			catalogs,
			projectCatalogs,
		} = this.state;

		if (language !== nextProps.language) {

			window.location.reload();

		} else if (nextProps.modifiedTranslationMessages
			&& catalogs[language].massages !== nextProps.modifiedTranslationMessages[language]) {

			let messages = {};

			let currentLanguageCatalog = {
				...catalogs[language]
			};

			const projectMessages = unpackCatalog(projectCatalogs).messages;

			for (let key in projectMessages) {
				const trans = nextProps.modifiedTranslationMessages[language][key];
				if (trans || trans === 0) {
					messages[key] = trans;
				} else {
					messages[key] = projectMessages[key];
				}
			}

			currentLanguageCatalog = {
				...currentLanguageCatalog,
				messages: {
					...messages,
				}
			};

			this.setState(state => ({
				catalogs: {
					...state.catalogs,
					[language]: currentLanguageCatalog
				}
			}));
		}
	}

	render() {

		// Does the environment support HTML 5 history
		const supportsHistory = typeof window !== 'undefined' && 'pushState' in window.history;

		const {language, catalogs, languageData, antI18n} = this.state;

		if (!catalogs[language]) {
			return null;
		}

		return <ConfigProvider locale={antI18n}>
			<I18nManager language={language} catalogs={catalogs} languageData={languageData}>
				<MobxProvider {...mobxStore}>
					<Suspense fallback={<div className="lsd-suspense"><Spin spinning/></div>}>
						<Router
							forceRefresh={!supportsHistory}
						>
							{renderRoutes(this.props.routes)}
						</Router>
					</Suspense>
				</MobxProvider>
			</I18nManager>
		</ConfigProvider>;
	}
}

const renderRoutes = routes => {
	return (
		<Switch>
			{routes.map((route, index) => {
				if (typeof route.component === 'object') {
					const LazyComponent = route.component;
					return (
						<Route
							key={`${route.path}_${index}`}
							path={route.path}
							exact={!!route.exact}
							render={props => (
								<LazyComponent {...props}/>
							)}
						/>

					);
				}

				if (!!route.routes) {
					const RoutesComponent = route.component;
					return (
						<Route
							key={`${route.path}_${index}`}
							path={route.path}
							exact={!!route.exact}
							render={props => (
								<RoutesComponent
									{...props}
									route={route}
								>
									{renderRoutes(route.routes)}
								</RoutesComponent>
							)}
						/>
					);
				}

				return (
					<Route
						key={`${route.path}_${index}`}
						path={route.path}
						exact={!!route.exact}
						component={route.component}
					/>
				)
			})}
		</Switch>
	);
}

export default App;


