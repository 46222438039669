import AccountLsdModel from './AccountLsdModel';
import UserModel       from './fondationsModels/UserModel';
import axios           from 'axios';
import CompanyCookie                  from './CompanyCookie';
import ConfigProxy                    from '../tools/ConfigProxy';
import currentModelXMercureService    from '../currentModelXMercureService';
import GroupLsdModel                  from './GroupLsdModel';
import i18n                           from '../components/i18n';
import jwtDecode                      from 'jwt-decode';
import MeModel                        from './MeModel';
import MercureTokenModel              from './MercureTokenModel';
import notification                   from 'antd/lib/notification';
import qs                             from 'qs';
import Raven                          from 'raven-js';
import SessionUser                    from './SessionUser';
import store                          from '../store';
import {action, computed, observable} from 'mobx';
import {ApiCollection}                from 'modelx-jts-common';
import {getIriFromId}                 from '../tools/lsdTools';
import {Model}                        from '@mathquis/modelx';
import {UserRights}                   from '../../proto/Service/Fondations/UserRights_pb';

const WITH_REDIRECTION_TO_COMPANY = true;
const WITHOUT_REDIRECTION_TO_COMPANY = false;

const client = axios.create({
	baseURL: ConfigProxy.getServiceConfig('fondations', 'api_endpoint'),
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/x-www-form-urlencoded'
	},
	responseType: 'json'
});

export default class Session extends Model {

	public accountModel: UserModel;
	public companyCookie: CompanyCookie;
	public lsdGroups: ApiCollection;
	public me: MeModel;
	public user: SessionUser;

	public protoUserRights: UserRights;

	@observable
	private loggedIn = false;

	@observable
	private processing = true;

	@observable
	private refreshing = false;

	@computed
	public get isProcessing(): boolean {
		return this.processing;
	}

	@computed
	public get isRefreshing(): boolean {
		return this.refreshing;
	}

	@computed
	public get isLoggedIn(): boolean {
		return this.loggedIn;
	}

	@computed
	public get isExpired(): boolean {
		return this.user.get('user_data.exp', 0) < Math.ceil(new Date().valueOf() / 1000);
	}

	@computed
	public get isAdmin(): boolean {
		return !!this.user && this.user.isAdmin();
	}

	@computed
	public get isManager(): boolean {
		return !!this.user && this.user.isManager();
	}

	@computed
	public get isSuperAdmin(): boolean {
		return !!this.user && this.user.isSuperAdmin();
	}

	@computed
	public get userUri() {
		const currentUserId = this.accountModel.id;
		const currentUserUri = getIriFromId(AccountLsdModel, currentUserId);
		return currentUserUri;
	}

	public login(username, password) {
		this.setIsProcessing(true);

		this.user
			.set({
				id: 'current',
				user_data: {
					user_informations: {
						avatar: '',
						firstName: '',
						lastName: ''
					}
				},
				refreshToken: '',
				token: '',
			})
			.save();

		return client('/login_check', {
			method: 'post',
			data: qs.stringify({
				_username: username,
				_password: password,
				from: 1 // TODO: Use the correct value otherwise it does not work if missing
			})
		})
			.then((response) => {
				return this.onLoggedIn(response, WITH_REDIRECTION_TO_COMPANY);
			})
			.finally(
				() => this.setIsProcessing(false)
			);
	}

	public refresh(): Promise<void> {
		if (this.refreshing) {
			return;
		}

		this.refreshing = true;

		return client('/token/refresh', {
			method: 'post',
			data: {
				refresh_token: this.user.get('refreshToken'),
				from: 1,
			},
		})
			.then(async response => {
				this.onLoggedIn(response);
			})
			.catch(() => {
				this.refreshing = false;
				this.realLogout();
			});
	}

	public realLogout() {
		this.clear();
		// this.destroy();

		store.appStore.clearStore();
		store.organigramStore.clearStore();

		this.loggedIn = false;
		this.setIsProcessing(false);

		this.user.clear();
		this.user.destroy();

		this.protoUserRights = null;

		this.accountModel.clear();
		this.me.clear();
		this.lsdGroups.clear();

		Raven.setUserContext({});
	}

	public logout() {
		// call each time there is a 401
		// whereas we want it to be call only when refresh fail
		// see realLogout method
	}

	public get token() {
		return this.user.get('token');
	}

	public get locale() {
		return 'fr';
	}

	public getRoleUrnArr(): string[] {
		if (this.protoUserRights) {
			return this.protoUserRights.getRolesList().map(r => {
				const urn = r.getUrn();

				if (!urn) {
					return '';
				}

				return `${urn.getPartition()}:${urn.getService()}:${urn.getResource()}:${urn.getIdentifier()}`;
			});
		}

		return [];
	}

	@action
	public setIsProcessing(value: boolean) {
		this.processing = value;
	}

	@action
	protected async onLoggedIn(response, companyRedirection = WITHOUT_REDIRECTION_TO_COMPANY) {
		this.user
			.set({
				user_data: jwtDecode(response.data.token),
				token: response.data.token,
				refreshToken: response.data.refresh_token,
			});

		await this.user.save();

		try {
			this._updateProtoUserRights();

			const roleUrnArr = this.getRoleUrnArr();

			// Seul un user lsd peut accéder à LSD
			if (
				!roleUrnArr.includes('jts:lsd:role:ROLE_LSD_USER') &&
				!roleUrnArr.includes('jts:lsd:role:ROLE_LSD_MANAGER') &&
				!roleUrnArr.includes('jts:lsd:role:ROLE_LSD_ADMIN') &&
				!roleUrnArr.includes('jts:lsd:role:ROLE_LSD_SUPER_ADMIN')
			) {
				throw new Error();
			}

			await this
				.init(companyRedirection)
				.catch(() => {
					// just catch the error when this has not been previously created
				});

			this.loggedIn = true;

			Raven.setUserContext({
				id: this.accountModel.id,
				email: this.accountModel.get('email'),
				username: this.accountModel.get('username')
			});
		} catch (err) {
			notification.error({
				description: '',
				message: i18n._('Vous ne disposez pas des autorisations requises pour accéder à cette interface'),
				className: 'app-notification error'
			});

			this.realLogout();
		}

		this.refreshing = false;

		return true;
	}

	protected async initialize(options = {}) {
		super.initialize(options);

		this._setProcessing(true);

		this.user = new SessionUser();

		try {
			await this.user.fetch();

			this._updateProtoUserRights();

			if (this.user.get('token') && this.user.get('id')) {
				await this.init().catch(() => {
					// just catch the error
				});

				this._setLoggedIn(true);

				Raven.setUserContext({
					id: this.accountModel.id,
					email: this.accountModel.get('email'),
					username: this.accountModel.get('username')
				});
			}
		} catch (err) {
			// just catch the error if user doesn't exist
			console.error(err);
		}

		this._setProcessing(false);
	}

	protected async init(companyRedirection = WITHOUT_REDIRECTION_TO_COMPANY): Promise<any> {
		const promises = [];

		this.accountModel = new UserModel();
		this.accountModel.set({
			id: this.protoUserRights.getUrn().getIdentifier()
		});
		promises.push(this.accountModel.fetch());

		this.companyCookie = new CompanyCookie();
		this.companyCookie.set({id: this.accountModel.id});

		promises.push(
			this.companyCookie
				.fetch()
				.then(() => {
					if (companyRedirection && this.companyCookie.get('company')) {
						window.location.href = `/companies/${this.companyCookie.get('company')}/my-tasks`;
					}

					return true;
				})
				.catch(() => {
					// just catch error if not found
				})
		);

		this.lsdGroups = new ApiCollection(GroupLsdModel);
		promises.push(this.lsdGroups.list());

		this.me = new MeModel();
		promises.push(
			this.me
				.fetch()
				.then(() => {
					if (this.me.id) {
						store.appStore.loadAllCurrentUserAndMenuData(this.me.id);
					}

					return true;
				})
		);

		// Récupération du token mercure
		promises.push(
			new MercureTokenModel()
				.fetch()
				.then(mercureModel => {
					currentModelXMercureService.setToken(mercureModel.get('token'));
					return true;
				})
		);

		return Promise.all(promises);
	}

	@action
	private _setProcessing(value: boolean) {
		this.processing = value;
	}

	@action
	private _setLoggedIn(value: boolean) {
		this.loggedIn = value;
	}

	private _updateProtoUserRights() {
		this.protoUserRights = UserRights.deserializeBinary(
			new TextEncoder().encode(this.user.get(['user_data', 'platform.token.owner']))
		);
	}
}
