import React        from 'react';
import Row          from 'antd/lib/row';
import Col          from 'antd/lib/col';
import Form         from 'antd/lib/form';
import UserModel    from '../../../models/fondationsModels/UserModel';
import i18n         from '../../../components/i18n';
import Trans        from '../../../components/Trans';
import {
	JtsForm,
	IJtsFormProps,
}                   from 'react-jts-common';
import SelectGender from '../../../components/Selects/SelectGender';
import Input        from '../../../genericComponents/Input';

export interface IModifyAccountInfoFormProps extends IJtsFormProps {
	model: UserModel;
}

class ModifyAccountInfoForm extends React.Component<IModifyAccountInfoFormProps> {

	public async componentDidMount() {
		const {form, model} = this.props;

		const values = {
			gender: model.get('gender'),
			firstName: model.get('firstName'),
			lastName: model.get('lastName'),
			defaultEntertainment: model.get('defaultEntertainment') || undefined
		};

		form.setFieldsValue(values);
	}

	public render() {
		const {
			form,
			model,
		} = this.props;

		const { getFieldDecorator } = form;

		return <JtsForm
			{...this.props}
			notificationSuccessMessage={<Trans id="Les informations du compte ont bien été modifiées." />}
			notificationErrorMessage={<Trans id="Les informations du compte n'ont pas été modifiées." />}
			onSubmit={params => model.patch(params)}
			mapParams={values => ({
				gender: values.gender,
				firstName: values.firstName,
				lastName: values.lastName,
				defaultEntertainment: values.defaultEntertainment,
			})}
		>
			<Row gutter={20} type="flex" align="top">

				{/* GENDER */}
				<Col xs={24} md={12}>
					<Form.Item
						label={<Trans id="Genre" />}
					>
						{getFieldDecorator('gender', {
							rules: [
								{
									required: true,
									message: <Trans id="Veuillez sélectionner un genre" />,
								},
							],
						})(
							<SelectGender
								placeholder={i18n._('Choisir un genre')}
							/>
						)}
					</Form.Item>
				</Col>

				{/* FIRST_NAME */}
				<Col xs={24} md={12}>
					<Form.Item
						label={<Trans id="Prénom" />}
					>
						{getFieldDecorator('firstName', {
							rules: [
								{
									required: true,
									message: <Trans id="Veuillez renseigner le prénom" />,
								},
							],
						})(
							<Input
								placeholder={i18n._('Prénom')}
							/>
						)}
					</Form.Item>
				</Col>

				{/* LAST_NAME */}
				<Col xs={24} md={12}>
					<Form.Item
						label={<Trans id="Nom" />}
					>
						{getFieldDecorator('lastName', {
							rules: [
								{
									required: true,
									message: <Trans id="Veuillez renseigner le nom" />,
								},
							],
						})(
							<Input
								placeholder={i18n._('Nom')}
							/>
						)}
					</Form.Item>
				</Col>
			</Row>
		</JtsForm>;
	}
}

export default Form.create<IModifyAccountInfoFormProps>()(ModifyAccountInfoForm);
